import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import BlogPost from '../templates/blog-post';
import { graphql } from 'gatsby';
import CodeExample from '../components/CodeExample';
export const _frontmatter = {
  "title": "Character Animation",
  "date": "2018-02-13",
  "promo": "grids",
  "description": "How to create advanced character animations with Spine",
  "color": "#eee53f"
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = BlogPost;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`Ever felt like the UIs you create are lifeless and a little boring? Here's one way to spice things up: add character animations. You don't need to be building a game to do this - even regular sites and apps can use some of that extra love and `}<a parentName="p" {...{
        "href": "/post/polish/"
      }}>{`polish`}</a>{` that character animation brings!`}</p>
    <p>{`I can't think of anything in this world more boring than a `}<em parentName="p">{`login form`}</em>{`. But check out what `}<a parentName="p" {...{
        "href": "https://twitter.com/dsenneff"
      }}>{`Darin Senneff`}</a>{` did to liven up `}<a parentName="p" {...{
        "href": "https://codepen.io/dsenneff/pen/QajVxO?editors=1010"
      }}>{`this one`}</a>{`:`}</p>
    <p><img parentName="p" {...{
        "src": "/gif/animated-form.gif",
        "alt": "character animated form"
      }}></img></p>
    <p>{`I love how the yeti fellow gets more and more excited as you type! What a fun experience. A `}<em parentName="p">{`fun`}</em>{` login form, who would have thought?`}</p>
    <p>{`Or look at what the `}<a parentName="p" {...{
        "href": "https://infinite.red"
      }}>{`Infinite Red`}</a>{` team did for the `}<a parentName="p" {...{
        "href": "https://infinite.red/ChainReactConf"
      }}>{`Chain React`}</a>{` conf page - wait for the train to zip by in the background :)`}</p>
    <p><img parentName="p" {...{
        "src": "/gif/reddesign.gif",
        "alt": "chain react animation"
      }}></img></p>
    <p>{`I love this kind of animation - and much prefer it over the overdone "animate in content on scroll" that so many sites use these days.`}</p>
    <p>{`A lot of people have asked how I create animations in my games `}<a parentName="p" {...{
        "href": "https://flexboxzombies.com"
      }}>{`Flexbox Zombies`}</a>{` and `}<a parentName="p" {...{
        "href": "https://gridcritters.com"
      }}>{`Grid Critters`}</a>{`, so here's an intro to get you started.`}</p>
    <h2>{`Skeletal Animation`}</h2>
    <p>{`There are a ton of ways to animate things on the web. Darin's yeti is SVG and `}<a parentName="p" {...{
        "href": "https://greensock.com/gsap"
      }}>{`GSAP`}</a>{` - same as I use in my interceptor ships in `}<a parentName="p" {...{
        "href": "https://gridcritters.com"
      }}>{`Grid Critters`}</a>{`. Infinite Red's train is a simple png image with a CSS animation - for fun open your browser's devtools and change the `}<inlineCode parentName="p">{`#train`}</inlineCode>{` div's `}<inlineCode parentName="p">{`animation-duration`}</inlineCode>{` to speed it up or slow it down.`}</p>
    <p>{`My current favorite animation technique is what's called `}<em parentName="p">{`skeletal animation`}</em>{`. It's called this because you create bone structures, attach image assets to the bones, and then animate the underlying bones. You can create really cool, advanced animations this way. Such as critters walking:`}</p>
    <p><img parentName="p" {...{
        "src": "/gif/critters-walking.gif",
        "alt": "critter walk animation"
      }}></img></p>
    <p>{`Or the pilot Meg and her trusty AI companion controlling a shuttle - notice how Meg works the shuttle's controls as the user is typing code in game:`}</p>
    <p><img parentName="p" {...{
        "src": "/gif/landing-animation.gif",
        "alt": "shuttle landing animation"
      }}></img></p>
    <p>{`Or zombies getting slaughtered by an epic crossbow:`}</p>
    <p><img parentName="p" {...{
        "src": "/gif/killing-zombies.gif",
        "alt": "zombie death animation"
      }}></img></p>
    <h2>{`Process`}</h2>
    <p>{`I'll take you through my entire process from start to finish so you can create your own awesome animations.`}</p>
    <h3>{`Inspiration`}</h3>
    <p>{`Characters start out as an idea in my head, and I draw from a few sources of inspiration. Meg, AI, and the shuttle were heavily influenced by one of my favorite movies as a kid: Flight of the Navigator.`}</p>
    <p><img parentName="p" {...{
        "src": "/img/flight-navigator.jpg",
        "alt": "flight of the navigator"
      }}></img></p>
    <h3>{`Rough Sketch`}</h3>
    <p>{`Once I have an idea of a fun character, I put on my art director hat and sketch out several random ideas using the iPad, the Apple pencil, and the `}<a parentName="p" {...{
        "href": "https://www.fiftythree.com/paper"
      }}>{`Paper`}</a>{` app - further developing the character's attributes/story/world in my mind.`}</p>
    <p><img parentName="p" {...{
        "src": "/img/meg-sketch.jpg",
        "alt": "Meg drawing sketch"
      }}></img></p>
    <h3>{`Concept Refinement`}</h3>
    <p>{`I then work with amazing illustrators to refine and polish the idea over several iterations:`}</p>
    <p><img parentName="p" {...{
        "src": "/img/shuttle-draft.jpg",
        "alt": "shuttle draft one"
      }}></img></p>
    <p>{`Exploring several variations:`}</p>
    <p><img parentName="p" {...{
        "src": "/img/shuttle-draft2.jpg",
        "alt": "shuttle draft two"
      }}></img></p>
    <p>{`And keeping the best pieces from each of them until at last a perfect final emerges:`}</p>
    <p><img parentName="p" {...{
        "src": "/img/ship-final.jpg",
        "alt": "Ship final"
      }}></img></p>
    <p>{`Meg went through this same process, starting out pretty rough:`}</p>
    <p><img parentName="p" {...{
        "src": "/img/pilot-sketch1.jpg",
        "alt": "pilot draft one"
      }}></img></p>
    <p>{`Becoming more and more like you know her now:`}</p>
    <p><img parentName="p" {...{
        "src": "/img/pilot-sketch2.jpg",
        "alt": "pilot draft two"
      }}></img></p>
    <p>{`She had a bit of a hair crisis:`}</p>
    <p><img parentName="p" {...{
        "src": "/img/pilot-sketch3.jpg",
        "alt": "pilot draft three"
      }}></img></p>
    <p>{`But turned out pretty great in the end:`}</p>
    <p><img parentName="p" {...{
        "src": "/img/meg-final.jpg",
        "alt": "Meg final"
      }}></img></p>
    <p>{`Finally we get to put them together - the Captain belongs with her ship:`}</p>
    <p><img parentName="p" {...{
        "src": "/img/meg-ship-final.jpg",
        "alt": "Meg ship final"
      }}></img></p>
    <h3>{`Layer Sprites`}</h3>
    <p>{`Now that the artwork is in a good spot, it's time to get it ready for animation. I use a tool called `}<a parentName="p" {...{
        "href": "https://esotericsoftware.com/"
      }}>{`Spine`}</a>{` that makes skeletal animations easy. First step is to use the Photoshop script that comes with Spine to export the entire document from Photoshop as individual layers.`}</p>
    <p><img parentName="p" {...{
        "src": "/img/ship-parts.jpg",
        "alt": "ship sprites"
      }}></img></p>
    <p>{`Sorry Meg! In this step it looks like her ship was hit with some kind of particle decelerator.`}</p>
    <h3>{`Skeleton`}</h3>
    <p>{`These separate layers are then imported and repositioned in Spine. I draw the "bones" for areas that I want to animate. That includes Meg, AI, the ship's door and landing gear etc. I attach specific layers to specific bones so that when the bones move, so do the layers attached to them.`}</p>
    <p><img parentName="p" {...{
        "src": "/img/spine-setup.jpg",
        "alt": "spine setup"
      }}></img></p>
    <p>{`Now every layer/bone can be animated separately on the timeline, making possible any animation you can imagine!`}</p>
    <p><img parentName="p" {...{
        "src": "/gif/meg-animation.gif",
        "alt": "meg animation"
      }}></img></p>
    <h3>{`JavaScript API`}</h3>
    <p>{`At some point you have to stop endlessly tweaking your animations and call them `}<strong parentName="p">{`done`}</strong>{` - I spent an entire hack weekend on just a few of these animations! In any case once the animations are dope, I export the character from Spine to a set of files we can work with in JavaScript. Spine comes with a canvas/WebGL runtime that lets you control your animations with JavaScript:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`this.shuttle.state.setAnimation(0, 'success', false)
this.shuttle.state.addAnimation(0, 'dropstairs', false, 1)
`}</code></pre>
    <p>{`This is an imperative API so if you're using React like I am or any other framework that likes to control the DOM, you'll need to work around it. In my case I'm dual rendering React components to both the DOM (for positioning) and a WebGL canvas simultaneously. Here's the story of `}<a parentName="p" {...{
        "href": "/post/easy-on-the-webgl/"
      }}>{`how I landed on that approach`}</a>{` if you like stories.`}</p>
    <h2>{`Character Animation FTW`}</h2>
    <p>{`Once you've got JavaScript hooks to trigger your animations, you're all set! You can change where your yeti is looking based on number of characters typed into a login form. Your train can zoom across the screen at a set interval or scroll position. Your shuttle can open the latch for incoming critters when the user types the correct CSS Grid code.`}</p>
    <p>{`The possibilities are endless! The most important thing is to use character animation to delight your users and have a blast while you're at it.`}</p>
    <p><strong parentName="p">{`Try it`}</strong>{` yourself: breathe new life into your work or side project by adding a simple character animation. I look forward to seeing what you create!`}</p>
    <p>{`If you want to dig into Spine I recommend following their `}<a parentName="p" {...{
        "href": "https://esotericsoftware.com/spine-user-guide"
      }}>{`User Guide`}</a>{`.`}</p>
    <p>{`Happy animating!`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      